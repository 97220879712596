import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const Label: React.FunctionComponent<LabelProps> = (props) => {
  const { children, fullWidth, className } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 600,
      padding: '10px 45px',
      textAlign: 'center',
      width: fullWidth ? '100%' : 'auto',
      zIndex: 900,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }));

  const classes = useStyles();

  return <div className={clsx([classes.root, className && className])}>{children}</div>;
};

interface LabelProps {
  className?: React.ReactNode;
  children: React.ReactNode;
  fullWidth?: boolean;
}

export default Label;
