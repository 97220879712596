import React from 'react';
import clsx from 'clsx';
import { Box, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import currency from 'currency.js';
import isNull from 'lodash/isNull';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { styleConfig } from '../../config/config';
import { calculatePriceMonthly } from '../utils/utils';
import Label from './Common/Label';
import SpecialOffersItemPrice from './SpecialOffersItemPrice';

const SpecialOffersItem: React.FunctionComponent<SpecialOffersItemProps> = (props) => {
  const {
    variant,
    agreementTerms,
    annualMileage,
    initialRental,
    isSearch,
    leaseType,
    index,
  } = props;

  const capId = variant.post_meta_fields.capid;
  const baseImageUrl = `https://cdn.imagin.studio/getImage?customer=${process.env.GATSBY_SITE_IMAGIN_ID}&capVehicleType=car&capId=${capId}&randomPaint=true&zoomType=fullscreen&zoomLevel=30&countryCode=GB&safeMode=true&&fileType=webp`;

  const primaryImage = `${baseImageUrl}&angle=01`;
  const secondaryImage = `${baseImageUrl}&angle=05`;
  const tertiaryImage = `${baseImageUrl}&angle=23`;

  const getImage = () => {
    const i = index;

    if (i === 1) {
      return primaryImage;
    } else if (i === 2) {
      return secondaryImage;
    } else if (i === 3) {
      return tertiaryImage;
    }
  };

  const image = getImage();

  const specs = variant && variant.post_meta_fields && variant.post_meta_fields.specs;
  const isSpecialOffer = variant && variant.acf && variant.acf.special_offer;
  const specialOfferText = variant && variant.acf && variant.acf.special_offer_info_bar;
  const brandTitle = variant && variant.post_meta_fields && variant.post_meta_fields.brand_title;
  const variantTitle = variant && variant.post_meta_fields && variant.post_meta_fields.title;
  const variantUrl = variant && variant.post_meta_fields && variant.post_meta_fields.variant_url;

  const placeholder = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo-ph.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            height: 372
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'block',
      position: 'relative',
      marginBottom: 25,
      border: '2px #DDD solid',
      margin: '0 auto',

      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
      },
    },

    title: {
      color: styleConfig.color.text.darkgrey,
      fontFamily: styleConfig.fonts.header,
      fontSize: 25,
      margin: 0,

      [theme.breakpoints.down('lg')]: {
        fontSize: 21,
      },
    },
    imageRoot: {
      maxHeight: '279px',
      marginBottom: '2.79rem',
      height: '372px',

      [theme.breakpoints.down('md')]: {
        height: 'auto',
        maxHeight: 'none',
        marginBottom: 0,
      },
    },
    imageSpecialOffer: {
      marginTop: 60,
    },
    image: {
      maxHeight: '100%',
      objectFit: 'cover',
      width: '100%',
    },
    subtitle: {
      color: styleConfig.color.text.gold,
      display: 'block',
      margin: '0 0 15px',
    },
    specs: {
      margin: 0,
      padding: 0,
    },
    specsItem: {
      color: styleConfig.color.text.lightergrey,
      marginBottom: 0,

      '& p': {
        margin: 0,
        fontSize: 12,
      },
      '& p:first-of-type': {
        fontWeight: 600,
        fontSize: 14,
      },
    },
    buttonsRoot: {
      [theme.breakpoints.down('sm')]: {
        '& $buttonRoot': {
          marginBottom: 15,
        },
      },
    },
    buttonRoot: {
      padding: '15px 5px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 120,
    },
    buttonBusiness: {
      border: `2px ${styleConfig.color.text.gold} solid`,

      [theme.breakpoints.down('lg')]: {
        height: 137,
        padding: '10px 5px',
      },

      [theme.breakpoints.down('md')]: {
        height: 137,
        padding: '10px 5px',
      },
    },
    buttonTitle: {
      color: styleConfig.color.text.darkgrey,
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0.5,
      margin: 0,
      textTransform: 'uppercase',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      [theme.breakpoints.down('lg')]: {
        fontSize: 13,
        marginright: 0,
        padding: '0 10px',
      },

      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    buttonPrice: {
      color: styleConfig.color.text.darkgrey,
      fontSize: 30,
      fontWeight: 500,
      margin: 0,
      display: 'block',
      marginRight: 15,

      [theme.breakpoints.down('lg')]: {
        marginRight: 0,
        flexDirection: 'column',
        fontSize: 25,
      },
    },
    buttonPriceRoot: {
      lineHeight: 1,
    },
    price: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('lg')]: {
        marginRight: 10,
        flexDirection: 'column',
        fontSize: 25,
      },
    },
    label: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 900,
      minHeight: 64,
    },
    titleRoot: {
      minHeight: 77,
    },
    titleWrap: {
      height: 86,
    },
    content: {
      padding: '0 25px 25px 25px',
    },
  }));

  const classes = useStyles();

  const defaultValues = {
    agreementTerms: 48,
    initialRental: 12,
    annualMileage: 8000,
  };

  const getItemPrice = (years: number) => {
    const months = years * 12;
    return currency(
      calculatePriceMonthly(
        leaseType,
        months,
        initialRental || defaultValues.initialRental,
        annualMileage ? annualMileage : defaultValues.annualMileage,
        variant
      ),
      { precision: 0, symbol: '£' }
    ).format();
  };

  const personalPrice = currency(
    calculatePriceMonthly(
      'personal',
      agreementTerms || defaultValues.agreementTerms,
      initialRental || defaultValues.initialRental,
      annualMileage ? annualMileage : defaultValues.annualMileage,
      variant
    ),
    { precision: 0, symbol: '£' }
  ).format();

  const businessPrice = currency(
    calculatePriceMonthly(
      'business',
      agreementTerms || defaultValues.agreementTerms,
      initialRental || defaultValues.initialRental,
      annualMileage ? annualMileage : defaultValues.annualMileage,
      variant
    ),
    { precision: 0, symbol: '£' }
  ).format();

  const agreementYears = {
    48: [4, 2, 3],
    36: [3, 2, 4],
    24: [2, 3, 4],
  };

  const isSpecialOfferVisible = isSpecialOffer && !isSearch;

  const noSpecs =
    specs.spec_BHP === 0 && specs.spec_0_62 === 0 && specs.spec_MPG3 === 0 && specs.spec_CO2 === 0;

  const isElectric =
    variant && variant.post_meta_fields && variant.post_meta_fields.fuel === 'Electric';

  const isZeroNonElectricCO2 =
    !isElectric &&
    variant &&
    variant.post_meta_fields &&
    variant.post_meta_fields.specs &&
    variant.post_meta_fields.specs.spec_CO2 === 0;

  const isZeroNonElectricMPG =
    !isElectric &&
    variant &&
    variant.post_meta_fields &&
    variant.post_meta_fields.specs &&
    variant.post_meta_fields.specs.spec_MPG3 === 0;

  return (
    <React.Fragment>
      {variant && (
        <Link className={classes.root} aria-label={variantTitle} to={variantUrl}>
          {isSpecialOfferVisible && (
            <Label className={classes.label} fullWidth>
              {specialOfferText || 'Special Offer'}
            </Label>
          )}
          <div
            className={clsx([
              classes.imageRoot,
              isSpecialOfferVisible && classes.imageSpecialOffer,
            ])}
          >
            {primaryImage ? (
              <img loading="lazy" className={classes.image} src={image} alt={variantTitle} />
            ) : (
              <GatsbyImage
                alt=""
                image={placeholder.file.childImageSharp.gatsbyImageData}
                className={classes.image}
                imgStyle={{ objectFit: 'contain' }}
                loading="lazy"
              />
            )}
          </div>

          <div className={classes.content}>
            <div className={classes.titleWrap}>
              <Typography component="h3" variant="h3" className={classes.title}>
                {brandTitle}
              </Typography>

              <Typography component="span" className={classes.subtitle}>
                {variantTitle}
              </Typography>
            </div>

            <Grid container spacing={3}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.specs}>
                <div className={classes.specsItem}>
                  <Typography component="p">
                    {noSpecs && !isNull(specs) ? 'n/a' : specs.spec_BHP} BHP
                  </Typography>
                  <Typography component="p">Power</Typography>
                </div>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.specs}>
                <div className={classes.specsItem}>
                  <Typography component="p">
                    {noSpecs && !isNull(specs) ? 'n/a' : specs.spec_0_62} secs
                  </Typography>
                  <Typography component="p">0-62mph</Typography>
                </div>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.specs}>
                <div className={classes.specsItem}>
                  <Typography component="p">
                    {(noSpecs && !isNull(specs)) || isZeroNonElectricMPG ? 'n/a' : specs.spec_MPG3}{' '}
                    mpg
                  </Typography>
                  <Typography component="p">Efficiency</Typography>
                </div>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.specs}>
                <div className={classes.specsItem}>
                  <Typography component="p">
                    {(noSpecs && !isNull(specs)) || isZeroNonElectricCO2 ? 'n/a' : specs.spec_CO2}{' '}
                    g/km
                  </Typography>
                  <Typography component="p">Emissions</Typography>
                </div>
              </Grid>
            </Grid>

            {leaseType && agreementTerms && isSearch ? (
              <Box className={classes.buttonsRoot}>
                <Grid container spacing={3}>
                  <SpecialOffersItemPrice
                    handleItemPrice={getItemPrice}
                    price={leaseType === 'business' ? businessPrice : personalPrice}
                    primary
                    years={agreementYears[agreementTerms][0]}
                    leaseType={leaseType}
                    isSearch={isSearch}
                  />
                </Grid>

                <Grid container spacing={3}>
                  <SpecialOffersItemPrice
                    handleItemPrice={getItemPrice}
                    years={agreementYears[agreementTerms][1]}
                    price={leaseType === 'business' ? businessPrice : personalPrice}
                    leaseType={leaseType}
                    isSearch={isSearch}
                  />
                  <SpecialOffersItemPrice
                    handleItemPrice={getItemPrice}
                    years={agreementYears[agreementTerms][2]}
                    price={leaseType === 'business' ? businessPrice : personalPrice}
                    leaseType={leaseType}
                    isSearch={isSearch}
                  />
                </Grid>
              </Box>
            ) : (
              <React.Fragment>
                <Box className={classes.buttonsRoot}>
                  <Grid container spacing={3}>
                    <SpecialOffersItemPrice
                      handleItemPrice={getItemPrice}
                      price={businessPrice}
                      leaseType="business"
                      isSearch={isSearch}
                    />
                    <SpecialOffersItemPrice
                      handleItemPrice={getItemPrice}
                      price={personalPrice}
                      leaseType="personal"
                      isSearch={isSearch}
                    />
                  </Grid>
                </Box>
              </React.Fragment>
            )}
          </div>
        </Link>
      )}
    </React.Fragment>
  );
};

interface SpecialOffersItemProps {
  index: number;
  agreementTerms?: number;
  annualMileage?: number;
  leaseType?: string;
  isSearch?: boolean;
  initialRental?: number;
  variant: any;
}

export default SpecialOffersItem;
