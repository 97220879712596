import React from 'react';
import { Grid, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Config
import { styleConfig } from '../../config/config';

const SpecialOffersItemPrice: React.FunctionComponent<SpecialOffersItemPriceProps> = (props) => {
  const { leaseType, price, years, primary, handleItemPrice, isSearch } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    buttonsRoot: {
      [theme.breakpoints.down('sm')]: {
        '& $buttonRoot': {
          marginBottom: 15,
        },
      },
    },
    buttonRoot: {
      padding: 15,
      position: 'relative',
      border: `2px ${styleConfig.color.text.darkgrey} solid`,

      [theme.breakpoints.down('lg')]: {
        padding: '10px 5px',
        height: 137,
      },

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
    },
    buttonRootSearch: {
      border: 'none',
      backgroundColor: styleConfig.color.bg.altLightGrey,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',

      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      },
    },
    buttonRootprimary: {
      padding: 25,
      display: 'flex',
      backgroundColor: styleConfig.color.bg.darkgrey,
      position: 'relative',
      justifyContent: 'space-between',

      [theme.breakpoints.down('lg')]: {
        padding: 15,
      },
    },
    term: {
      fontSize: 12,
      fontWeight: 800,
    },
    termPrimary: {
      color: styleConfig.color.text.white,
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 'normal',
    },
    leaseType: {
      color: styleConfig.color.text.gold,
      fontSize: 12,
      fontWeight: 800,
      letterSpacing: 'normal',

      [theme.breakpoints.down('lg')]: {
        fontSize: 11,
      },

      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    buttonBusiness: {
      border: `2px ${styleConfig.color.text.gold} solid`,
    },
    buttonTitle: {
      color: styleConfig.color.text.darkgrey,
      marginBottom: 10,
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0.5,
      margin: 0,
      textTransform: 'uppercase',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',

      [theme.breakpoints.down('lg')]: {
        fontSize: 13,
        alignItems: !primary ? 'center' : 'flex-start',
        textAlign: !primary ? 'center' : 'flex-start',
        maxWidth: 150,
        margin: !primary ? '0 auto' : 'initial',
      },
    },
    buttonPrice: {
      color: styleConfig.color.text.darkgrey,
      fontSize: 28,
      fontWeight: 800,
      margin: 0,
      display: 'block',
      marginRight: 15,

      [theme.breakpoints.down('lg')]: {
        marginRight: 0,
        fontSize: 25,
      },
    },
    buttonPricePrimary: {
      color: styleConfig.color.text.white,
      fontSize: 44,
      fontWeight: 800,
      marginRight: 10,

      [theme.breakpoints.down('lg')]: {
        fontSize: 35,
        marginRight: 5,
        marginLeft: 5,
      },
    },
    buttonPricingLabels: {
      '& > span': {
        display: 'block',
        fontSize: 12,
        margin: 0,
        color: styleConfig.color.text.lightgrey,
      },
      '& > span:last-of-type': {
        color: styleConfig.color.text.gold,
      },
    },
    buttonBusinessText: {
      color: styleConfig.color.text.gold,
    },
    buttonPricingLabelsBusiness: {
      '& > span:last-of-type': {
        color: styleConfig.color.text.gold,
      },
    },
    icon: {
      position: 'absolute',
      right: 0,
      top: 'calc(50% - 24px)',
      fontSize: 48,
      color: styleConfig.color.text.lightgrey,
    },
    iconPrimary: {
      fontSize: 60,
      top: 'calc(50% - 30px)',

      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    iconSecondary: {
      top: 'calc(50% - 20px)',
      color: leaseType === 'business' ? styleConfig.color.bg.gold : styleConfig.color.bg.darkgrey,
      fontSize: 40,

      [theme.breakpoints.down('lg')]: {
        top: 'calc(50% - 25px)',
        fontSize: 50,
      },
    },
    price: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        fontSize: 25,
      },
    },
    pricePrimary: {
      marginRight: 25,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '60%',
      justifyContent: 'center',

      [theme.breakpoints.down('lg')]: {
        marginRight: 0,
        flexDirection: 'row',
      },

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  }));

  const classes = useStyles();

  if (isSearch) {
    return (
      <Grid item sm xs={primary ? 12 : 6}>
        <div
          className={clsx(
            primary
              ? classes.buttonRootprimary
              : clsx([classes.buttonRoot, classes.buttonRootSearch])
          )}
        >
          <Typography
            component="span"
            className={clsx(
              classes.buttonTitle,
              leaseType === 'business' && classes.buttonBusinessText
            )}
          >
            <div className={clsx([classes.term, primary && classes.termPrimary])}>
              {years} Years {!primary && 'from'}
            </div>
            {primary && <div className={classes.leaseType}>{leaseType} Lease From</div>}
          </Typography>

          <div className={clsx([classes.price, primary && classes.pricePrimary])}>
            <Typography
              component="span"
              className={clsx([classes.buttonPrice, primary && classes.buttonPricePrimary])}
            >
              {price === '0' ? 'POA' : handleItemPrice(years)}
            </Typography>

            <div
              className={clsx(
                classes.buttonPricingLabels,
                leaseType === 'business' && classes.buttonPricingLabelsBusiness
              )}
            >
              <span>({leaseType === 'business' ? 'Excl. VAT' : 'Inc. VAT'})</span>
              <span>per month</span>
            </div>

            {primary && (
              <ChevronRightIcon className={clsx([classes.icon, primary && classes.iconPrimary])} />
            )}
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid item sm xs={12}>
      <div className={clsx(classes.buttonRoot, leaseType === 'business' && classes.buttonBusiness)}>
        <Typography
          component="span"
          className={clsx(
            classes.buttonTitle,
            leaseType === 'business' && classes.buttonBusinessText
          )}
        >
          {leaseType} Lease From
        </Typography>

        <div className={classes.price}>
          <Typography component="span" className={classes.buttonPrice}>
            {price === '0' ? 'POA' : price}
          </Typography>
          <div
            className={clsx(
              classes.buttonPricingLabels,
              leaseType === 'business' && classes.buttonPricingLabelsBusiness
            )}
          >
            <span>({leaseType === 'business' ? 'Excl. VAT' : 'Inc. VAT'})</span>
            <span>per month</span>
          </div>

          <ChevronRightIcon className={clsx([classes.icon, classes.iconSecondary])} />
        </div>
      </div>
    </Grid>
  );
};

interface SpecialOffersItemPriceProps {
  handleItemPrice: Function;
  agreementTerms?: number;
  annualMileage?: number;
  leaseType: string;
  isSearch?: boolean;
  initialRental?: number;
  price: string;
  years?: 4 | 3 | 2;
  primary?: boolean;
}

export default SpecialOffersItemPrice;
