import React from 'react';
import {
  Box,
  Container,
  createStyles,
  Grid,
  Typography,
  Tab,
  Tabs,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isNull from 'lodash/isNull';
import SwipeableViews from 'react-swipeable-views';
import uniqueId from 'lodash/uniqueId';

import { styleConfig } from '../../config/config';

import MuiButton from './MuiButton';
import Section from './Common/Section';
import SpecialOffersItem from './SpecialOffersItem';
import Title from '../components/Common/Title';
import RouteLink from './RouteLink';

function TabContainer({ children, dir }: TabContainerProps) {
  return (
    <Box dir={dir} style={{ padding: '50px 0', overflowX: 'hidden' }}>
      {children}
    </Box>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      textAlign: 'center',
      '& button': {
        marginTop: 30,
      },
    },
    footerTitle: {
      marginBottom: 50,
      color: styleConfig.color.text.black,
    },
    tab: {
      fontWeight: 600,
    },
    title: {
      position: 'relative',
      textAlign: 'center',
      zIndex: 5,

      '& h2': {
        textTransform: 'uppercase',
        marginBottom: '4rem',
      },
    },
  })
);

const ContentModuleSpecialOffers: React.FunctionComponent<ContentModuleSpecialOffersProps> = (
  props
) => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const classes = useStyles();

  const { tabs, title, subtitle } = props;

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  function handleChangeIndex(index: number) {
    setValue(index);
  }

  const savedPreferences =
    typeof window !== 'undefined' &&
    localStorage.getItem('content') &&
    JSON.parse((typeof window !== 'undefined' && localStorage.getItem('content')) || '');

  let count = 0;

  return (
    <Section bg={styleConfig.color.bg.white}>
      <Container maxWidth="xl">
        {!isNull(title) && !isNull(subtitle) && title && (
          <Box className={classes.title}>
            <Title
              alignment="center"
              component="h2"
              isSubtitle
              subtitleText={subtitle}
              subtitleType="subtitle1"
              titleCaps
              titleColor={styleConfig.color.text.darkgrey}
              titleHeavy
              titleSize="h2"
              titleText={title}
            />
          </Box>
        )}

        {tabs && tabs.length >= 1 && (
          <React.Fragment>
            {tabs.length >= 2 && (
              <Tabs
                indicatorColor="primary"
                onChange={handleChange}
                textColor="primary"
                value={value}
                variant="fullWidth"
              >
                {tabs &&
                  tabs.map((tab, index) => (
                    <Tab
                      className={classes.tab}
                      key={uniqueId()}
                      label={tab.title || `Offers ${index + 1}`}
                    />
                  ))}
              </Tabs>
            )}

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {tabs &&
                tabs.map(
                  (offer, index) =>
                    offer &&
                    offer.derivatives && (
                      <TabContainer dir={theme.direction} key={`cm-special-offers-${index}`}>
                        <div hidden={value !== index}>
                          <Grid container spacing={3} justifyContent="center">
                            {offer.derivatives &&
                              offer.derivatives.map((variant) => {
                                if (!variant.post_meta_fields?.variant_url) {
                                  return null;
                                }

                                count++;

                                if (count === 4) {
                                  count = 1;
                                }

                                return (
                                  <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={12}
                                    key={variant.post_meta_fields.variant_url}
                                  >
                                    <SpecialOffersItem
                                      isSearch={false}
                                      agreementTerms={
                                        (savedPreferences && savedPreferences.agreementTerms) || 48
                                      }
                                      annualMileage={
                                        (savedPreferences && savedPreferences.annualMileage) || 8000
                                      }
                                      initialRental={
                                        (savedPreferences && savedPreferences.initialRental) || 12
                                      }
                                      variant={variant}
                                      index={count}
                                    />
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </div>
                      </TabContainer>
                    )
                )}
            </SwipeableViews>

            <Grid container justifyContent="center" alignItems="center">
              <footer className={classes.footer}>
                <Typography className={classes.footerTitle}>
                  Initial payment & terms apply, subject to status
                </Typography>

                <RouteLink href="/special-offers/">
                  <MuiButton
                    aria-label="View all special offers"
                    name="special-offers"
                    variant="outlined"
                  >
                    View all special offers
                  </MuiButton>
                </RouteLink>
              </footer>
            </Grid>
          </React.Fragment>
        )}
      </Container>
    </Section>
  );
};

interface TabContainerProps {
  children?: React.ReactNode;
  dir?: string;
}

interface ContentModuleSpecialOffersProps {
  title: string;
  subtitle: string;
  tabs: {
    title: string;
    derivatives: {
      isSpecialOffer: boolean;
      image: string;
      manufacturer: string;
      series: string;
      model: string;
      specs: {
        spec060: number;
      };
      post_meta_fields: {
        variant_url;
      };
      specialOfferText: string;
      businessPrice: number;
      personalPrice: number;
    }[];
  }[];
}

export default ContentModuleSpecialOffers;
